<template>
  <div class="positionT0L0">
    <p class="title">基本信息</p>
    <div class="teacher-form">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        labelAlign="left"
      >
        <a-form-model-item label="会员卡名称" prop="memberName">
          <a-input v-model="form.memberName" placeholder="请输入会员卡名称">
            <div slot="addonAfter">{{ form.memberName.length }}/8</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="副标题" prop="subtitle">
          <a-input v-model="form.subtitle" placeholder="请输入副标题">
            <div slot="addonAfter">{{ form.subtitle.length }}/12</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          label="会员卡封面"
          required
          :class="{ 'has-error': !form.coverImg && isCheckCoverImg }"
        >
          <CropperUpload
            :imageUrl="form.coverImg"
            :options="coverOptions"
            @avatarfn="coverUrlFn"
            avatarTextTop="建议尺寸686*340px，JPG、PNG、JPEG格式"
            avatarTextBottom="小于1M"
          />
          <div
            class="ant-form-explain"
            v-if="!form.coverImg && isCheckCoverImg"
          >
            请上传会员卡封面
          </div>
        </a-form-model-item>
        <a-form-model-item label="商家编码" placeholder="请输入商家编码">
          <a-input v-model="form.commodityCode" :maxLength="50">
            <div slot="addonAfter">{{ form.commodityCode.length }}/50</div>
          </a-input>
          <div class="tips">商家对该会员卡自定义的编码(唯一)</div>
        </a-form-model-item>

        <p class="title mb30">会员类型</p>
        <a-form-model-item label="会员类型" prop="memberType">
          <a-select style="width: 150px" v-model="form.memberType">
            <a-select-option v-for="item in memberTypeOptions" :key="item.key">{{ item.value }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <p class="title mb30">新用户专享</p>
        <a-form-model-item
          label="新用户专享"
          prop="rights"
          :wrapper-col="{ span: 16 }"
        >
          <a-radio-group
            v-model="form.onlynew"
            :options="freshMemberNoOptions"
          ></a-radio-group>
        </a-form-model-item>

        <p class="title mb30">会员价格</p>
        <a-form-model-item label="有效期" prop="days">
          <a-input-number
            style="width: 120px"
            v-model="form.days"
            :min="1"
            :precision="0"
            placeholder="请输入有效期"
          />
          <a-select style="width: 120px" v-model="picker">
            <a-select-option v-for="item in pickerList" :key="item.key">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="价格" prop="price" class="p-price">
          <a-input-number
            v-model="form.price"
            :min="0.01"
            :max="99999"
            :step="0.01"
          />
          <span class="price-label">元</span>
        </a-form-model-item>

        <p class="title mb30">会员权益</p>
        <a-form-model-item
          label="权益设置"
          prop="rights"
          :wrapper-col="{ span: 16 }"
        >
          <a-radio-group
            v-model="form.rights"
            :options="rightsList"
          ></a-radio-group>
          <a-button type="primary" v-if="form.rights == 1" @click="addCourse"
            >添加课程</a-button
          >
          <div v-if="tableList.length > 0 && form.rights == 1">
            <div class="p-container">
              <a-list item-layout="horizontal" :data-source="tableList">
                <a-list-item slot="renderItem" slot-scope="item">
                  <div class="info-wrap flexbox middle justify">
                    <div class="item-info flexbox flex1">
                      <img class="p-img" :src="item.coverUrl" alt="" />
                      <div class="p-info flex1">
                        <div class="p-wrap">
                          <div class="p-info-name more-t">{{ item.name }}</div>
                          <!-- <div class="p-info-price">￥{{ item.price }}</div> -->
                          <div
                            v-show="item.pricingType == 0"
                            class="p-info-price"
                          >
                            免费
                          </div>
                          <div
                            v-show="item.pricingType == 1"
                            class="p-info-price"
                          >
                            ￥{{ item.price.toFixed(2) }}
                          </div>
                          <div
                            v-show="item.pricingType == 2"
                            class="p-info-price"
                          >
                            不支持单独售卖
                          </div>
                        </div>
                        <div class="p-info-type">
                          课程类型：{{ item.courseTypeText }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <a @click="delHandle(item)"
                        ><a-icon type="close" style="color: #b2b2b2"
                      /></a>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </a-form-model-item>

        <p class="title mb30">会员详情</p>
        <a-form-model-item
          label="详情描述"
          class="description"
          :wrapper-col="{ span: 20 }"
          prop="synopsis"
        >
          <UEditor :content="form.synopsis" @editorFn="briefFn"></UEditor>
        </a-form-model-item>
        <p class="title">第三方信息</p>
        <a-form-model-item
          label="抖店商品编码"
          placeholder="请输入抖店商家编码"
        >
          <a-input v-model="form.trillCommodityCode" :maxLength="50"> </a-input>
          <div class="tips">
            抖店为该课程的生成的唯一商品编码（或叫抖店商品ID）
          </div>
        </a-form-model-item>
        <p class="title">社群弹窗</p>
        <a-form-model-item
          label="社群二维码"
          required
          :class="{ 'has-error': !form.serviceUrl && isCheckServiceUrl }"
        >
          <CropperUpload
            :imageUrl="form.serviceUrl"
            :options="serviceUrlOptions"
            @avatarfn="serviceUrlFn"
            avatarTextTop="上传小书童的企业微信二维码"
            avatarTextBottom="建议尺寸280*280px，JPG、PNG、JPEG格式 小于1M"
          />
          <div
            class="ant-form-explain"
            v-if="!form.serviceUrl && isCheckServiceUrl"
          >
            请上传社群二维码
          </div>
        </a-form-model-item>
        <a-form-model-item label="弹窗标题" prop="popupTitle">
          <a-input v-model="form.popupTitle" />
        </a-form-model-item>
        <a-form-model-item label="弹窗内容" prop="popupContent">
          <a-textarea v-model="form.popupContent" :rows="4" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <CheckboxModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :type="0"
      :selectedRowKeys="selectedRowKeys"
      :selectedRows="tableList"
      @cancel="cancel"
      @ok="ok"
    />
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="backClick">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
    </FooterToolBar>
    <!-- 预览信息 -->
    <div class="preview_messages">
      <div class="preview_img">
        <img :src="form.coverImg ? form.coverImg : defaultImgUrl" alt="" />
      </div>
      <div class="preview_box">
        <div class="message_title">{{ form.memberName }}</div>
        <div class="message_subtitle">{{ form.subtitle }}</div>
        <div class="message_price" v-show="form.memberName">
          <img src="../../../assets/images/coin_big@2x.png" alt="" />
          <span>{{ form.price.toFixed(2) }}</span>
          <span> / </span>
          <span>{{ form.days }}天</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import CropperUpload from "@/components/file/CropperUpload";
import UEditor from "@/components/ueditor/ueditor";
import CheckboxModal from "@/components/courseMember/CheckboxModal";
import {
  MemberAddApi,
  MemberUpdateApi,
  MemberDetailApi,
} from "@/request/api/marketingCenter";

export default {
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    CropperUpload,
    UEditor,
    CheckboxModal,
  },
  data() {
    return {
      defaultImgUrl:
        "https://test-kodo.shenyiedu.com//image/20210831/1432521178836242432.jpg",
      isDisabled: false, // 保存的按钮是否禁用
      form: {
        memberType: 0,
        onlynew: 0,
        memberName: "",
        subtitle: "",
        coverImg: "",
        commodityCode: "",
        days: 365,
        price: 0.01,
        rights: 0,
        synopsis: "",
        courses: [],
        trillCommodityCode: "",
        serviceUrl: "",
        popupTitle: '',
        popupContent: ''
      },
      rules: {
        popupTitle: [{ required: true, message: '请输入弹窗标题', trigger: 'blur' }],
        popupContent: [
          { required: true, message: '请输入弹窗内容', trigger: 'blur' },
          { max: 100, message: "最多输入100个字符" },
        ],
        memberType: [{ required: true, message: "请选择会员类型", trigger: "blur" }],
        memberName: [
          { required: true, message: "请输入会员卡名称", trigger: "blur" },
          { max: 8, message: "最多输入8个字符" },
        ],
        subtitle: [{ max: 12, message: "最多输入12个字符" }],
        days: [{ required: true, message: "请输入有效期", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        rights: [
          { required: true, message: "请选择权益设置", trigger: "change" },
        ],
        synopsis: [
          { required: true, message: "请输入详情描述", trigger: "blur" },
        ],
      },
      isCheckCoverImg: false, // 判断是否上传会员卡封面
      isCheckServiceUrl: false, // 判断是否上传小书童二维码
      picker: "",
      pickerList: [
        { key: "", label: "day", value: "天" },
        // { key: 1, label: 'month', value: '月' },
        // { key: 2, label: 'season', value: '季' },
        // { key: 3, label: 'year', value: '年' }
      ],
      memberTypeOptions: [
        { key: 0, label: "0", value: "一般会员" },
        { key: 1, label: "1", value: "全局会员主卡" },
        { key: 2, label: "2", value: "全局会员时长卡" }
      ],
      freshMemberNoOptions: [
        { value: 1, label: "是" },
        { value: 0, label: "否" },
      ],
      rightsList: [
        { value: 0, label: "全部课程可用" },
        { value: 1, label: "部分课程可用" },
      ],
      columns: [
        { title: "课程名称", dataIndex: "name", key: "name" },
        { title: "定价", dataIndex: "price", key: "price", width: "15%" },
        {
          title: "课程类型",
          dataIndex: "courseTypeText",
          key: "courseTypeText",
          width: "15%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "key",
          width: "10%",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableList: [],
      modalVisible: false,
      selectedRowKeys: [],
      coverOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 686, //默认生成截图框宽度
        autoCropHeight: 340, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
      serviceUrlOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 280, //默认生成截图框宽度
        autoCropHeight: 280, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    if (id != 0) this.getDetail(id)
  },
  methods: {
    async getDetail(id) {
      const { code, data } = await MemberDetailApi({ id: id })
      if (code !== 200) return
      this.form = data
      this.tableList = this.form.courses
      for (let i in this.tableList) {
        this.selectedRowKeys.push(this.tableList[i].courseNo)
      }
    },
    // 添加课程
    addCourse() {
      this.modalVisible = true;
    },
    // 模态框 - 取消事件
    cancel() {
      this.modalVisible = false;
    },
    // 模态框 - 确认事件
    ok(selectedRowKeys, selectedRows) {
      this.modalVisible = false;
      this.form.courses = this.selectedRowKeys = selectedRowKeys;
      this.tableList = selectedRows;
    },
    // 选中数据后table 删除事件
    delHandle(record) {
      for (let i in this.tableList) {
        if (this.tableList[i].courseNo == record.courseNo) {
          this.tableList.splice(i, 1);
          this.selectedRowKeys.splice(i, 1);
        }
      }
    },
    // 获取封面图链接
    coverUrlFn(imgData) {
      this.form.coverImg = imgData.url;
      this.isDisabled = false;
    },
    // 获取小书童图片链接
    serviceUrlFn(imgData) {
      this.form.serviceUrl = imgData.url;
      this.isDisabled = false;
    },
    // 获取教师详情
    briefFn(desc) {
      this.form.synopsis = desc;
    },
    // 返回上一页
    backClick() {
      this.$router.push("/marketingCenter/memberManage");
    },
    // 保存
    keepClick() {
      if (!this.form.coverImg) {
        this.isCheckCoverImg = true
        this.$message.error('请上传会员封面')
        return
      }
      if (!this.form.serviceUrl) {
        this.isCheckServiceUrl = true
        this.$message.error('请上传小书童二维码')
        return
      }
      if (this.form.rights === 0)  this.form.courses = []

      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error('还有必填项未填')
          return
        }
        if (this.tableList.length <= 0 && this.form.rights === 1) {
          this.$message.error('请添加商品')
          return
        } 
        this.isDisabled = true
        // 如果不增加课程操作，只抽取课程的 courseNo 提交表单
        const courses = []
        if (this.form.courses.length > 0 && this.form.courses[0].courseNo) {
          for (const i in this.form.courses) {
            courses.push(this.form.courses[i].courseNo)
          }
          this.form.courses = courses
        }
        const params = {
          ...this.form
        }
        const id = this.$route.params.id
        if (+id !== 0) {
          params.id = id
          const { code } = await MemberUpdateApi(params)
          if (code !== 200) return
          this.$message.info('更新成功')
        } else {
          const { code } = await MemberAddApi(params)
          if (code !== 200) return
          this.$message.info('添加成功')
        }
        this.$router.push('/marketingCenter/memberManage')
        this.isDisabled = false
      })
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  color: #000;
  &::before {
    content: "";
    border-left: 3px solid #1890ff;
    margin-right: 8px;
    padding: 2px 0;
  }
}
.mb30 {
  margin-bottom: 30px;
}
.mt10 {
  margin-top: 10px;
}
.price-label {
  margin-left: 20px;
}
/deep/ .p-price .ant-input-number {
  width: 120px;
}
.teacher-form {
  position: relative;
  margin-top: 30px;
}
.tag-label-refresh {
  position: absolute;
  right: -50px;
  color: #1890ff;
  cursor: pointer;
}
.tag-label-page {
  position: absolute;
  right: -170px;
  color: #1890ff;
  cursor: pointer;
}
.conclusion-label-num {
  position: absolute;
  right: 10px;
}
.p-container {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px 0;
  // background-color: #F2F2F2;
}
.info-wrap {
  width: 100%;
  padding: 0 20px;
  .item-info {
    margin-right: 14px;
    .p-img {
      width: 100px;
      height: 85px;
      object-fit: contain;
      border-radius: 2px;
    }
    .p-info {
      margin-left: 10px;
      .p-wrap {
        height: 67px;
      }
      .p-info-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
      }
      .p-info-price {
        font-size: 14px;
        color: #fb6161;
        margin-top: 4px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .p-info-type {
        font-size: 12px;
        color: #888;
        margin: 0 14px 0 0;
      }
    }
  }
}
// 改变滚动条样式
.p-container::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
}
.p-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.p-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/deep/.description .ant-form-item-control {
  line-height: unset;
}
// 预览信息
.preview_messages {
  position: absolute;
  top: 50px;
  right: 300px;
  .preview_img {
    width: 343px;
    height: 170px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .preview_box {
    width: 100%;
    height: 100%;
    // padding: 20px;
    // box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    color: #f9eed0;

    .message_title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 2px;
      position: absolute;
      top: 30px;
      left: 20px;
    }
    .message_subtitle {
      font-size: 15px;
      letter-spacing: 2px;
      position: absolute;
      top: 60px;
      left: 20px;
    }
    .message_price {
      position: absolute;
      bottom: 35px;
      left: 20px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        vertical-align: middle;
      }
      span {
        &:nth-child(2) {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
}
.tips {
  margin-top: 10px;
  color: #ccc;
}
</style>
